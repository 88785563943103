import store from '../../../store'

function mergeOrderIndex(orders) {
  //得到合并后订单下标
  let orderNos = [],
    returnList = []
  for (const orderListElement of orders) {
    orderNos.push(orderListElement.individualId)
  }
  let simpleList = [...new Set(orderNos)] //订单号集合
  for (const simpleListElement of simpleList) {
    let a = [] //暂存一个集合
    for (let i = 0; i < orderNos.length; i++) {
      if (simpleListElement === orderNos[i]) {
        a.push(i)
      }
    }
    returnList.push(a)
  }
  return returnList
}

function setPrice(orderStatus, shouldPayAmount, actuallyPaidAmount, split) {
  //支付状态，应付金额，实付金额
  switch (orderStatus) {
    case '支付完成':
      return actuallyPaidAmount.toFixed(2)
    case '已退款':
      return actuallyPaidAmount.toFixed(2)
    case '部分退款':
      return actuallyPaidAmount.toFixed(2)
    case '部分支付':
      return (shouldPayAmount - actuallyPaidAmount).toFixed(2)
    case '未支付':
      if (shouldPayAmount == 0) {
        return ''
      } else {
        if (split) {
          return (shouldPayAmount - actuallyPaidAmount).toFixed(2)
        } else {
          return shouldPayAmount.toFixed(2)
        }
      }
  }
}

function mergeOrder(orders) {
  //合并订单
  let returnList = []
  for (const order of mergeOrderIndex(orders)) {
    //一个订单
    const message = {
      baseMessage: [],
      payMessage: [],
      sortFlag: 3,
      refunded: false
    }
    //let sortStatus = []//暂存状态
    let i = 0
    for (const orderElement of order) {
      //费用列表
      if (message.baseMessage.length === 0) {
        message.baseMessage = orders[orderElement].individualInfoList
      }
      //判断支付状态
      switch (orders[orderElement].orderStatus) {
        case '未支付':
          if (message.sortFlag === 0 || message.sortFlag === 3) {
            message.sortFlag = 0
          } else {
            message.sortFlag = 1
          }
          break
        case '部分支付':
          message.sortFlag = 1
          break
        case '支付完成':
          if (message.sortFlag === 2 || message.sortFlag === 3) {
            message.sortFlag = 2
          } else {
            message.sortFlag = 1
          }
          break
        default:
          i++
          if (order.length === i) {
            message.sortFlag = 4
          }
          message.refunded = true
          break
      }

      message.payMessage.push({
        subjectName: orders[orderElement].subjectName,
        orderId: orders[orderElement].id,
        id: orders[orderElement].subjectId,
        orderStatus: orders[orderElement].orderStatus,
        actuallyPaidAmount: orders[orderElement].actuallyPaidAmount,
        shouldPayAmount: orders[orderElement].shouldPayAmount, //提交的金额
        price: setPrice(
          orders[orderElement].orderStatus,
          orders[orderElement].shouldPayAmount,
          orders[orderElement].actuallyPaidAmount,
          store.state.split
        ),
        flag: '', //判断是否选中
        cusid: orders[orderElement].payTypeId //互斥
      })
    }
    returnList.push(message)
  }
  return returnList
}

function orderMessageSort(orders) {
  //按支付状态排序
  let a = [],
    b = [],
    c = [],
    d = [] // 未支付，部分支付，支付完成,
  let mergeorder = mergeOrder(orders)
  for (const orderMessage of mergeorder) {
    switch (orderMessage.sortFlag) {
      case 0:
        a.push(orderMessage)
        break
      case 1:
        b.push(orderMessage)
        break
      case 2:
        c.push(orderMessage)
        break
      case 4:
        d.push(orderMessage)
        break
      default:
        d.push(orderMessage)
        break
    }
  }
  return a.concat(b, c, d)
}
export { mergeOrderIndex, mergeOrder, orderMessageSort, setPrice }
