<template>
  <div class="detail_cell" @click="goToOrder">
    <!--  基础信息  -->
    <div class="detail_cell_base">
      <ul>
        <li>
          <span class="pay_title">{{ sortFlag }}</span>
        </li>
        <li v-for="(item, index) in showBaseMessage" :key="index">
          <span>{{ item.label + '：' }}</span>
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
    <!--  展开图标  -->
    <img
      v-if="iconShowFlag"
      v-show="showHidden"
      src="~@/assets/img/detail.png"
      class="detal_icon"
      @click.stop="showDetail"
      alt="详情"
    />
    <img
      v-if="iconShowFlag"
      v-show="!showHidden"
      src="~@/assets/img/hidden.png"
      class="detal_icon"
      @click.stop="showDetail"
      alt="详情"
    />
    <!--  分割线  -->
    <van-divider
      :style="{
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0 10px',
        margin: '5px 0 5px 0'
      }"
    />
    <!--  支付信息  -->
    <div class="detail_cell_pay">
      <span v-for="(item, index) in payMessages" :key="index" class="tag">{{
        item.label
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailCell',
  props: {
    sortFlag: String,
    baseMessages: Array,
    payMessages: Array
  },
  computed: {},
  data() {
    return {
      iconShowFlag: false, //展示图标
      showHidden: true, //显示详情图标
      iconPath: '', //图标路径
      showBaseMessage: [], //展示的基本信息
      showPayMessage: [] //展示缴费信息
    }
  },
  methods: {
    pageInit() {
      if (this.baseMessages.length > 3) {
        this.showBaseMessage = this.baseMessages.slice(0, 3)
        this.iconShowFlag = true
      } else {
        this.showBaseMessage = this.baseMessages
      }
    },
    showDetail() {
      //展示详细信息
      this.showHidden = !this.showHidden
      if (this.showHidden) {
        this.showBaseMessage = this.baseMessages.slice(0, 3)
      } else {
        this.showBaseMessage = this.baseMessages
      }
    },
    goToOrder() {
      //发射跳转事件
      this.$emit('goToOrderpage')
    }
  },
  watch: {
    baseMessages(newData) {
      this.baseMessages = newData
      this.pageInit()
    },
    payMessages(newData) {
      this.payMessages = newData
      this.showPayMessage = newData
    }
  },
  mounted() {
    this.pageInit()
  }
}
</script>

<style scoped lang="less">
.detail_cell {
  margin: 15px 0;
  padding: 8px;
  font-size: 13px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 2px 3px 5px gainsboro;

  .pay_title {
    color: #ed6a0c;
    font-weight: bold;
  }

  .detail_cell_base li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      margin: 0 10px;
    }
  }
  .detal_icon {
    margin: 0 10px;
    width: 13px;
    height: 13px;
  }
  .detail_cell_pay {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin: 5px 5px;
    .tag {
      background-color: #e7faf0;
      border-color: #d0f5e0;
      border: solid 1px;
      color: #13ce66;
      border-radius: 2px;
      padding: 2px 3px;
      margin: 5px 5px;
    }
  }
}
</style>
