<template>
  <div class="searchpage">
    <head-text :title="this.$store.state.title"></head-text>
    <!--  搜索框  -->
    <div class="search">
      <van-search
        v-model.trim="infoValue"
        show-action
        placeholder="请输入搜索关键词"
        autofocus
        @search="onSearch"
        shape="round"
      >
        <template #left-icon><div></div></template>
        <template #label>
          <van-popover
            v-model="showPopover"
            trigger="click"
            :actions="actions"
            @select="onSelect"
          >
            <template #reference>
              {{ selectItme }}
              <van-icon name="arrow-up" v-show="showPopover" />
              <van-icon name="arrow-down" v-show="!showPopover" />
            </template>
          </van-popover>
        </template>
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <!--  查询结果  -->
    <div class="result">
      <detail-cell
        v-for="(item, index) in orderMessages"
        :key="index"
        :sortFlag="sortFlagBy(item)"
        :base-messages="cellBaseMessage(item)"
        :pay-messages="cellPayMessage(item)"
        @goToOrderpage="jumpOrderPage(item)"
      ></detail-cell>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { judgeUA } from '@/utils/functions.js'
import { orderMessageSort } from '@/views/orderPage/simple/utils.js'
import request from '@/api/request'
import HeadText from '@/components/common/simple/HeadText.vue'
import DetailCell from '@/components/common/simple/DetailCell'

export default {
  name: 'SearchPage',
  data() {
    return {
      orderMessages: this.$store.state.orderMessages, //所有的订单信息
      selectItme: '',
      showPopover: false, //展示选择框
      actions: [],
      selectedInfoId: '', //infoId
      infoValue: '',
      selectedInfoValue: '' //infoValue
    }
  },
  components: { HeadText, DetailCell },
  computed: {
    sortFlagBy() {
      return (item) => {
        switch (item.sortFlag) {
          case 0:
            return item.refunded ? '未支付（有退款）' : '未支付'
          case 1:
            return item.refunded ? '部分支付（有退款）' : '部分支付'
          case 2:
            return item.refunded ? '支付完成（有退款）' : '支付完成'
          case 4:
            return '已退款'
        }
      }
    },
    cellBaseMessage() {
      return (message) => {
        let basemessage = []
        for (const baseElement of message.baseMessage) {
          basemessage.push({
            label: baseElement.value,
            value: baseElement.display
          })
        }
        return basemessage
      }
    },
    cellPayMessage() {
      return (message) => {
        let paymessage = []
        for (let i = 0; i < message.payMessage.length; i++) {
          paymessage.push({
            label: message.payMessage[i].subjectName,
            orderStatus: message.payMessage[i].orderStatus
          })
        }
        return paymessage
      }
    }
  },
  methods: {
    onSelect(action) {
      this.selectItme = action.text
      this.selectedInfoId = action.infoId
    },
    onSearch() {
      //搜索事件,回调参数当前输入的值
      request
        .get(
          `/payment/wx/school/order?infoId=${this.selectedInfoId}&infoValue=${this.infoValue}&templateId=${this.$route.params.templateId}`
        )
        .then((res) => {
          if (res.size <= 0) {
            // 清空搜索记录
            this.orderMessages = []
            this.$store.commit('setOrderMessages', this.orderMessages)
            Toast.fail('未找到订单！')
          } else {
            this.orderMessages = orderMessageSort(res.content)
            this.$store.commit('setOrderMessages', this.orderMessages)
          }
        })
        .catch((err) => {
          console.error(err)
          Toast.fail(err.message)
        })
    },
    jumpOrderPage(order) {
      this.$router.push('orderpage')
      this.$store.commit('setOderMessage', order)
    }
  },
  mounted() {
    // 获取AppID
    let that = this
    async function wxGetCode() {
      let templateId = that.$route.path.split('/')[2]
      let promiseAppId = await request.get(
        `/payment/wx/school/page/${templateId}/appid`
      )
      // 判断ua获取code
      if (
        judgeUA(that.$router) &&
        !that.$route.query.code &&
        promiseAppId.content
      ) {
        // 获取code
        let redirect = encodeURIComponent(
          process.env.VUE_APP_WX_REDIRECT_URL + that.$route.path
        )

        if (promiseAppId.content) {
          let appid = promiseAppId.content
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base#wechat_redirect`
        }
      }
    }
    try {
      wxGetCode()
    } catch (err) {
      console.error(err)
      Toast.fail(err.message)
    }
    //辅助接口
    request
      .get(
        `payment/wx/school/order/template/${this.$route.params.templateId}/forQuery`
      )
      .then((res) => {
        let actions = []
        for (const re of res.content.infoIdAndNames) {
          actions.push({ text: re.display, infoId: re.value })
        }
        this.$store.commit('setSplitOrder', res.content.split)
        this.$store.commit('setTitle', res.content.templateName)
        document.title = res.content.templateName
        this.selectItme = res.content.infoIdAndNames[0].display
        this.selectedInfoId = res.content.infoIdAndNames[0].value
        this.actions = actions
      })
      .catch((err) => {
        console.error(err)
        Toast.fail('辅助接口查询失败！')
      })
  }
}
</script>
<style scoped lang="less">
.searchpage {
  .search {
    .van-search__content {
      border: #1995fa solid 1px;
    }
    .van-dropdown-menu__bar {
    }
  }
  .result {
    margin: 20px 0;
    .base {
      font-weight: bold;
      font-size: 14px;
      padding: 0 0 5px 0;
      color: black;
    }
    .pay {
    }
  }
}
</style>
